@import url('https://fonts.googleapis.com/css?family=EB+Garamond:400|Montserrat:400,500,600,700&display=swap');

html, body {
  padding: 0;
  margin: 0;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes delayed-fadein {
  0%   { opacity: 0; }
  66%  { opacity: 0; }
  100% { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-moz-keyframes delayed-fadein {
  0%   { opacity: 0; }
  66%  { opacity: 0; }
  100% { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-webkit-keyframes delayed-fadein {
  0%   { opacity: 0; }
  66%  { opacity: 0; }
  100% { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-ms-keyframes delayed-fadein {
  0%   { opacity: 0; }
  66%  { opacity: 0; }
  100% { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-o-keyframes delayed-fadein {
  0%   { opacity: 0; }
  66%  { opacity: 0; }
  100% { opacity: 1; }
}
